/*
 * @Date: 2021-07-15 12:47:28
 * @LastEditors: Woz
 * @LastEditTime: 2021-09-18 12:18:28
 * @FilePath: \groundle_web\src\main.js
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


import 'fullpage.js/vendors/scrolloverflow'
import VueFullPage from 'vue-fullpage.js'
Vue.use(VueFullPage);

import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(VueAwesomeSwiper, {})

import ba from 'vue-ba'
Vue.use(ba, {
  siteId: "5112fb1a308e4fa8492e770ba0ac91ad"
});



Vue.config.productionTip = false
// import 'swiper/swiper-bundle.css'
import 'swiper/css/swiper.css'


import './util/rem'


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')