<!--
 * @Date: 2021-07-15 14:46:47
 * @LastEditors: Woz
 * @LastEditTime: 2021-09-03 18:32:50
 * @FilePath: \groundle_web\src\components\floatLogo.vue
-->

<template>
	<div>
		<img @click="goto('/')" :src="logoPath" class="logo" />
	</div>
</template>
<style scoped>
	.logo {
		width: 135px;
		position: absolute;
		z-index: 10;
		top: 25%;
		left: 40px;
		cursor: pointer;
	}
</style>
<script>
	export default {
		name: "vLogo",
		props: {},
		data() {
			return {};
		},
		methods: {
			goto(name) {
				console.log(name);
				if (this.$route.name == `/${name}`) {
					//
				} else {
					this.$emit("beforeLeave", {});
					this.$router.push(name);
				}
			},
		},
		computed: {
			logoPath() {
				switch (this.$route.name) {
					case "Map":
						return require("../assets/logo_map.png");
					case "Scent":
						return require("../assets/logo_map.png");
					default:
						return require("../assets/logo.png");
				}
			},
		},
	};
</script>
<style scoped>
</style>