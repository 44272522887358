<!--
 * @Date: 2021-07-15 12:47:28
 * @LastEditors: Woz
 * @LastEditTime: 2021-09-17 18:16:57
 * @FilePath: \groundle_web\src\views\Home.vue
-->
 
<template>
	<div class="home page">
		<!-- <img
			src="../assets/d_home.jpg"
			class="page"
			style="position: absolute; top: 0; left: 0; z-index: 5; opacity: 0.5"
		/> -->
		<vMenu @beforeLeave="clearPage"></vMenu>
		<vLogo></vLogo>
		<swiper style="cursor: pointer" ref="homeSwiper" :options="swiperOptions">
			<swiper-slide> <div class="swiper-slide home-p1"></div></swiper-slide>
			<swiper-slide> <div class="swiper-slide home-p2"></div></swiper-slide>
			<swiper-slide> <div class="swiper-slide home-p3"></div></swiper-slide>
			<swiper-slide> <div class="swiper-slide home-p4"></div></swiper-slide>
			<div class="swiper-pagination" slot="pagination"></div>
		</swiper>

		<div class="sound-holder holder SourceHanSansRegular">
			<img src="../assets/home_enter.png" class="enter" @click="handleScroll" />
			<br />
			<div>
				<div class="txt-holder" style="text-align: right">
					TURN ON THE SOUND
				</div>

				<img src="../assets/icon_vol.png" class="home-icon" />
				<div class="txt-holder" style="text-align: left">
					FOR A BETTER EXPERIENCE
				</div>
			</div>
		</div>
	</div>
</template>
<style scoped>
	.txt-holder {
		width: 300px;
		font-size: 16px;
		/* background: yellow; */
	}
	.enter {
		width: 91px;
		margin-bottom: 70px;
		cursor: pointer;
	}
	.home-p1 {
		background-image: url("../assets/home_p1.jpg");
	}

	.home-p2 {
		background-image: url("../assets/home_p2.jpg");
	}

	.home-p3 {
		background-image: url("../assets/home_p3.jpg");
	}
	.home-p4 {
		background-image: url("../assets/home_p4.jpg");
	}
	.swiper-container {
		width: 100%;
		height: 100%;
	}

	.home-p1,
	.home-p2,
	.home-p3,
	.home-p4 {
		background-size: cover;
		background-position: center;
	}
	.sound-holder {
		bottom: 48px;
		z-index: 10;
		color: white;

		font-size: 1rem;
	}

	.sound-holder * {
		display: inline-block;
	}

	.home-icon {
		width: 28px;
		padding: 0 25px 0 23px;
		vertical-align: middle;
	}
</style>
<script>
	import vLogo from "../components/floatLogo.vue";
	import vMenu from "../components/menu.vue";

	export default {
		name: "Home",
		data() {
			return {
				swiperOptions: {
					loop: true,

					autoplay: {
						delay: 2000, //4秒切换一次
					},
					effect: "fade",
				},
			};
		},
		components: { vLogo, vMenu },
		computed: {
			swiper() {
				return this.$refs.homeSwiper.$swiper;
			},
		},
		methods: {
			clearPage(val, callback) {
				callback("ok");
			},
			handleScroll() {
				console.log("SCROLL");
				// window.removeEventListener("mousewheel", this.handleScroll);
				this.$router.push("/Map");
			},
		},
		mounted() {
			// window.addEventListener("mousewheel", this.handleScroll, false);
			this.$ba.trackPageview(this.$route.path);
		},

		destroyed() {
			// 离开该页面需要移除这个监听的事件，不然会报错
			// window.removeEventListener("mousewheel", this.handleScroll);
		},
	};
</script>
