<!--
 * @Date: 2021-07-15 13:50:08
 * @LastEditors: Woz
 * @LastEditTime: 2021-09-15 17:40:41
 * @FilePath: \groundle_web\src\components\menu.vue
--> 
<template>
	<div
		class="menu"
		:class="{ 'txt-deep': router == 'Map' || router == 'Scent' }"
	>
		<div class="left-menu SourceHanSansRegular">
			<div class="menu-txt" @click="goto('/Scent')">SCENT</div>
			<div class="menu-txt" @click="goto('/About')">ABOUT</div>
			<div class="menu-txt" @click="goto('/Contact')">CONTACT</div>
			<div
				class="menu-txt"
				v-show="router != 'Map' && router != 'Home'"
				@click="goto('/Map')"
			>
				MAP
			</div>
		</div>

		<div class="right-menu">
			<div class="menu-txt" v-show="router != 'Order'" @click="jump">ORDER</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "vMenu",
		props: {},
		data() {
			return {};
		},
		methods: {
			goto(name) {
				// console.log(name);
				// console.log(this.$route.path);
				if (this.$route.path == `${name}`) {
					//
				} else {
					this.$emit("beforeLeave", {}, (val) => {
						// console.log(val);
						this.$router.push(name);
					});
					// this.$router.push(name);
				}
			},
			jump() {
				location.href = "https://www.coslets.com/";
			},
		},

		computed: {
			router() {
				return this.$route.name;
			},
		},
	};
</script>
<style scoped>
	.txt-deep {
		color: #493c33 !important;
	}

	.left-menu {
		float: left;
		margin-left: 30px;
	}
	.right-menu {
		float: right;
		margin-right: 30px;
	}
	.menu {
		position: absolute;
		top: 0;
		z-index: 10;
		color: white;
		font-size: 16px;
		margin-top: 80px;
		width: 100%;
	}
	.menu-txt {
		padding: 10px;
		display: inline;
		cursor: pointer;
	}
</style>